.row{
justify-content: center;
}

.p-l-40{
    padding-left:40px !important;
}
.p-t-40{
    padding-top:40px !important;
}

.form-input{
    margin: 10px;
   
}

.flex-box{
    display: flex;
}

.form-radio{
    margin: 10px;
}

.margin-left75{
    margin-left: 75px !important;
}

.w-700{
    display: flex;
    flex-direction: column;
    width: 700px;
    max-width: 700px;
    margin: 0px auto;
}

.imgName{
    position: absolute;
    top:7px;
    left:108px;
    height: 40px;
    background-color: #f0f0f0;
}

.align-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.w-120{
    width: 120px;
}

.star{
    color: red;
}


.modal.show .modal-dialog{
    max-width: 700px;
}

.form-group{
   display: flex;
   margin: 10px;   
}

.start-time{
    position: absolute;
    border: 0px;
    top: -42px;
    left: 10px;
    width: 98%;
    outline: none;
    background-color: #f0f0f0 !important;
}

.end-time{
    position: absolute;
    border: 0px;
    top: -42px;
    left: 10px;
    width: 98%;
    outline: none;
    background-color: #f0f0f0 !important;

}

.errorMsg{
    color: red;
}

.react-datepicker{
    top: -8px;
    margin-left: 20px;
}
.p-40{
    padding: 40px;
}

.form-control{
    background-color: #f0f0f0 !important;
}


.form-floating span{
    padding-right: 20px;
}

.m-t-3{
    margin-top: 2rem;
}
.title{
    text-align: center;
    margin-bottom: 30px;
}

.react-datepicker-wrapper>.react-datepicker__input-container input {

    width: 100% !important;
}

.bgackground{
    background-color:aqua;
}
